@mixin login{

    #login{
        width: 40vw;
        height: 45vh;
        border-radius: .3rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: map-get($map: $color, $key: color-600 );
        font-weight: 100;
        padding: 1rem .5rem .5rem .5rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

}