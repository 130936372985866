@mixin input{

    #input{
        width: 35vw;
        height: 20vh;
        border-radius: .3rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: map-get($map: $color, $key: color-600 );
        padding: .5rem .5rem .5rem .5rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    #inputwrapperout{
        display: flex;
        margin: 3rem 0rem 0rem -170rem;
        transition: 1s;
    }

    #inputwrapper{
        display: flex;
        margin: 3vh 0vw 0vh 46vw;
        transition: 1s;
    }

    #inputwrapperLowres{
        display: flex;
        margin: 0vh 0vw 0vh 72vw;
        transition: 1s;
    }

    #plotwrapperout{
        display: flex;
        margin: 0rem 0rem 0rem -170rem;
        transition: 1s;
    }

    #plotwrapper{
        display: flex; 
        margin: 0rem 0rem 0rem 10rem;
        transition: 1s;
    }



}