@mixin details{

    #details{
        width: 42vw;
        height: 30vh;
        border-radius: .3rem;
        background-image: url(../ASSETS/background.png); 
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: map-get($map: $color, $key: color-600 );
        padding: .5rem .5rem .5rem .5rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    #fg{
        width: 13vw;
        height: 4vh;
        border-radius: .3rem;
        background-image: url(../ASSETS/fg.png); 
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

}