@mixin range{

    #range{
        width: 76%;
        height: 100%;
        border-radius: .5rem;
        box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 6px, rgba(0, 0, 0, 0.50) 0px 1px 6px;
        margin-left: 5%;
       }

}