@mixin header{

    #header{
        display:flex; width: 100vw; height: auto;
        padding: .5rem .5rem .5rem .5rem;
        margin: 0rem 0rem 0rem 0rem;
        overflow: hidden;
        font-weight: 100;
    }


}