@mixin wrapper{

    .wrapper{
        display:flex; 
     }

     .wrap{ flex-wrap: wrap; }
     .absolute{ position: absolute; }
     .relative{ position: relative; }

     .width-10{ width: 10%; }
     .width-15{ width: 15%; }
     .width-20{ width: 20%; }
     .width-25{ width: 25%; }
     .width-30{ width: 30%; }
     .width-35{ width: 35%; }
     .width-40{ width: 40%; }
     .width-45{ width: 45%; }
     .width-50{ width: 50%; }
     .width-55{ width: 55%; }
     .width-60{ width: 60%; }
     .width-65{ width: 65%; }
     .width-70{ width: 70%; }
     .width-75{ width: 75%; }
     .width-80{ width: 80%; }
     .width-85{ width: 85%; }
     .width-90{ width: 90%; }
     .width-95{ width: 95%; }
     .width-100{ width: 100%; }

     .height-10{ height: 10%; }
     .height-15{ height: 15%; }
     .height-20{ height: 20%; }
     .height-25{ height: 25%; }
     .height-30{ height: 30%; }
     .height-35{ height: 35%; }
     .height-40{ height: 40%; }
     .height-45{ height: 45%; }
     .height-50{ height: 50%; }
     .height-55{ height: 55%; }
     .height-60{ height: 60%; }
     .height-65{ height: 65%; }
     .height-70{ height: 70%; }
     .height-75{ height: 75%; }
     .height-80{ height: 80%; }
     .height-85{ height: 85%; }
     .height-90{ height: 90%; }
     .height-95{ height: 95%; }
     .height-100{ height: 100%; }

     .padding-left-1{ padding-left: 1%; }
     .padding-left-2{ padding-left: 2%; }
     .padding-left-3{ padding-left: 3%; }
     .padding-left-4{ padding-left: 4%; }
     .padding-left-5{ padding-left: 5%; }
     .padding-left-10{ padding-left: 10%; }
     .padding-left-15{ padding-left: 15%; }
     .padding-left-20{ padding-left: 20%; }
     .padding-left-25{ padding-left: 25%; }
     .padding-left-30{ padding-left: 30%; }

     .padding-right-1{ padding-right: 1%; }
     .padding-right-2{ padding-right: 2%; }
     .padding-right-3{ padding-right: 3%; }
     .padding-right-4{ padding-right: 4%; }
     .padding-right-5{ padding-right: 5%; }
     .padding-right-10{ padding-right: 10%; }
     .padding-right-15{ padding-right: 15%; }
     .padding-right-20{ padding-right: 20%; }
     .padding-right-25{ padding-right: 25%; }
     .padding-right-30{ padding-right: 30%; }

     .padding-top-1{ padding-top: 1%; }
     .padding-top-2{ padding-top: 2%; }
     .padding-top-3{ padding-top: 3%; }
     .padding-top-4{ padding-top: 4%; }
     .padding-top-5{ padding-top: 5%; }
     .padding-top-10{ padding-top: 10%; }
     .padding-top-15{ padding-top: 15%; }
     .padding-top-20{ padding-top: 20%; }
     .padding-top-25{ padding-top: 25%; }
     .padding-top-30{ padding-top: 30%; }

     .padding-bottom-1{ padding-bottom: 1%; }
     .padding-bottom-2{ padding-bottom: 2%; }
     .padding-bottom-3{ padding-bottom: 3%; }
     .padding-bottom-4{ padding-bottom: 4%; }
     .padding-bottom-5{ padding-bottom: 5%; }
     .padding-bottom-10{ padding-bottom: 10%; }
     .padding-bottom-15{ padding-bottom: 15%; }
     .padding-bottom-20{ padding-bottom: 20%; }
     .padding-bottom-25{ padding-bottom: 25%; }
     .padding-bottom-30{ padding-bottom: 30%; }

     .margin-left-1{ margin-left: 1%; }
     .margin-left-2{ margin-left: 2%; }
     .margin-left-3{ margin-left: 3%; }
     .margin-left-4{ margin-left: 4%; }
     .margin-left-5{ margin-left: 5%; }
     .margin-left-10{ margin-left: 10%; }
     .margin-left-15{ margin-left: 15%; }
     .margin-left-20{ margin-left: 20%; }
     .margin-left-25{ margin-left: 25%; }
     .margin-left-30{ margin-left: 30%; }

     .margin-right-1{ margin-right: 1%; }
     .margin-right-2{ margin-right: 2%; }
     .margin-right-3{ margin-right: 3%; }
     .margin-right-4{ margin-right: 4%; }
     .margin-right-5{ margin-right: 5%; }
     .margin-right-10{ margin-right: 10%; }
     .margin-right-15{ margin-right: 15%; }
     .margin-right-20{ margin-right: 20%; }
     .margin-right-25{ margin-right: 25%; }
     .margin-right-30{ margin-right: 30%; }

     .margin-top-1{ margin-top: 1%; }
     .margin-top-2{ margin-top: 2%; }
     .margin-top-3{ margin-top: 3%; }
     .margin-top-4{ margin-top: 4%; }
     .margin-top-5{ margin-top: 5%; }
     .margin-top-10{ margin-top: 10%; }
     .margin-top-15{ margin-top: 15%; }
     .margin-top-20{ margin-top: 20%; }
     .margin-top-25{ margin-top: 25%; }
     .margin-top-30{ margin-top: 30%; }

     .margin-bottom-1{ margin-bottom: 1%; }
     .margin-bottom-2{ margin-bottom: 2%; }
     .margin-bottom-3{ margin-bottom: 3%; }
     .margin-bottom-4{ margin-bottom: 4%; }
     .margin-bottom-5{ margin-bottom: 5%; }
     .margin-bottom-10{ margin-bottom: 10%; }
     .margin-bottom-15{ margin-bottom: 15%; }
     .margin-bottom-20{ margin-bottom: 20%; }
     .margin-bottom-25{ margin-bottom: 25%; }
     .margin-bottom-30{ margin-bottom: 30%; }


     .border-up{ border-top: solid 1px map-get($map: $color, $key: color-700); }
     .border-down{ border-bottom: solid 1px map-get($map: $color, $key: color-700); }
     .border-start{ border-left: solid 1px map-get($map: $color, $key: color-700); }
     .border-end{ border-right: solid 1px map-get($map: $color, $key: color-700); }

     .testcolor{background-color: rgba(105, 138, 71, 0.5)};
     .white{background-color: rgba(255, 255, 255, 0.5)};

     .opacity-10{ opacity: .1; }
     .opacity-20{ opacity: .2; }
     .opacity-30{ opacity: .3; }
     .opacity-40{ opacity: .4; }
     .opacity-50{ opacity: .5; }
     .opacity-60{ opacity: .6; }
     .opacity-70{ opacity: .7; }
     .opacity-80{ opacity: .8; }
     .opacity-90{ opacity: .9; }

}