@mixin user{

    #user{
        width: 60rem;
        height: 3rem;
        padding: .5rem .5rem .5rem .5rem;
        margin: 0rem 0rem .5rem 0rem;
        border-radius: .5rem;
        background-color: rgb(217, 230, 242);
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
       }

}