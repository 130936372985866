@mixin form{

    .label{
        display: flex;
        width: 80%;
        height: auto;
         margin: .5rem .5rem .5rem .5rem;
    }

    .input{
        display: flex;
        width: 70%;
        height: auto;
        margin: .5rem .5rem .5rem .5rem;
        padding: .5rem .5rem .5rem .5rem;
        border-radius: .3rem;
    }

    #inputNarrow{
        display: flex;
        width: 50%;
        height: auto;
        margin: .5rem .5rem .5rem 1.5rem;
        padding: .5rem .5rem .5rem .5rem;
        border-radius: .3rem;
    }

    .select{
        display: flex;
        width: 60%;
        height: 2.6rem;
        margin: 0rem 0rem 0rem 0rem;
        padding: 0rem 0rem 0rem 0rem;
        border-radius: .3rem;
    }

}