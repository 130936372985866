@mixin on{

    #on{
     
        border-radius: .3rem;
        background-image: url(../ASSETS/on.png); 
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
        margin: 25rem 0rem 0rem 0rem;
    }

    #on:hover{

        border-radius: .3rem;
        background-image: url(../ASSETS/onHover.png); 
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
        margin: 25rem 0rem 0rem 0rem;
    }

    #onout{
        width: 10rem;
        height: 10rem;
        border-radius: .3rem;
        background-image: url(../ASSETS/on.png); 
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
        margin: 25rem 0rem 0rem -42rem;
        transition: 1s;
    }

    #videoon{
        display: flex;
        margin: 5rem 0rem 0rem 5rem;
    }

    #videoout{
        display: flex;
        margin: 5rem 0rem 0rem -120rem;
        transition: 1.5s;
    }

}